import React from "react"
import Logo from "../components/logo"
import Footer from "../components/footer"
import Container from "../components/container"
import styled from "styled-components"
/* import { Link } from "gatsby"; */

const StyledProjectsContainer = styled.div`
    /* le container positionne le contenu */

    .tag:first-child {
        margin-left: 0;
    }
    img {
        width: 100%;
        border-radius: 8px;
        border: 2px solid white;
    }
    h2:first-child {
        margin-top: 0;
    }

    .projects {
        margin-top: 32px;
    }

    .right {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 16px;
        grid-area: item2;
    }
    .left {
        grid-area: item1;
    }

    .right * {
        margin-top: 0;
    }
    .project_grid {
        display: grid;
        grid-template-rows: auto auto;
        grid-template-areas: "item2" "item1";
        row-gap: 16px;
    }

    p {
        font-size: 14px;
        margin-bottom: 0;
    }

    .stack {
        display: none;
    }

    @media (min-width: 720px) {
        .stack {
            display: block;
        }
        .project_grid {
            grid-template-rows: auto;
            grid-template-columns: 2fr 2fr;
            grid-template-areas: "item1" "item2";

            column-gap: 24px;
            row-gap: 0;
        }
        .left {
            grid-area: revert;
        }
        .right {
            grid-area: revert;
        }
        hr {
            margin-top: 48px;
            margin-bottom: 48px;
        }
    }
`

const review_chinese = {
    name: "review-chinese.io",
    challenge: "Learn Chinese",
    notable: "on-the-fly examples, infinite replayability, text-to-speech, smart review algorithm",
    tags: ["Education", "Productivity", "AI"],
    stack: ["React", "Firebase", "openAI API"],
    url: "https://review-chinese.io",
    imageurl: "review_chinese",
}

const stay_in_shape = {
    name: "stayinshape.io",
    challenge: "Schedule Workouts",
    notable: "actionable schedule, lightweight logging, opt-in measurements",
    tags: ["Planning", "Fitness"],
    stack: ["React", "Firebase"],
    url: "https://stayinshape.io",
    imageurl: "stay_in_shape",
}

const fezeta = {
    name: "fezeta.surge.sh",
    challenge: "Real time MMORPG",
    notable: "seamless reactive updates, real-time listeners, data protection, CRON tasks",
    tags: ["Game", "RPG", "Pixel Art"],
    stack: ["React", "Firebase", "Illustrator"],
    url: "https://fezeta.surge.sh/",
    imageurl: "fezeta",
}

const agame = {
    name: "ogameclassic.io",
    challenge: "Multiplayer Browser Game",
    notable: "seamless reactive updates, real-time listeners, data protection, CRON tasks",
    tags: ["Game", "Events", "Queue"],
    stack: ["React", "Firebase", "custom NPM package"],
    url: "https://ogameclassic.io/",
    imageurl: "agame",
}

const dis = {
    name: "Divorce International Simplifié",
    challenge: "Showcase Legal Service",
    notable: "branding, one-page format, portraits, logo",
    tags: ["Legal", "Branding", "Marketing"],
    stack: ["Webflow", "No-code"],
    url: "https://www.chamontin-morreelweber-avocats.com/",
    imageurl: "dis",
}

const vbd = {
    name: "Votre Bien Dévoué",
    challenge: "Lawyer-to-Lawyer Matching",
    notable:
        "Intuitive UI, Progressive Web App, Connect to an existing backend, Expand on an existing design system",
    tags: ["Legal", "Productivity", "Messaging"],
    stack: ["React", "Firebase"],
    url: "",
    imageurl: "vbd",
}

const antoineweber = {
    name: "antoineweber.com",
    challenge: "Impactful Personal Website",
    notable: "CMS stored articles to store articles backend, Existing design system, ",
    tags: ["Blog", "Personal Website", "Resume"],
    stack: ["React", "Gatsby", "Contentful CMS"],
    url: "https://www.antoineweber.com/",
    imageurl: "antoineweber",
}

const lexco = {
    name: "lexcoavocat.com",
    challenge: "Lawfirm Branding Website",
    notable: "Typography, Brand colors, One-page format, Communication",
    tags: ["Legal", "Branding"],
    stack: ["React", "Gatsby"],
    url: "https://www.lexcoavocats.com/",
    imageurl: "lexco",
}

/*   "Web Dev": "#013e5a",
    Travel: "#333",
    Lifestyle: "#333",
    Random: "#43807a",
  } */

export default function Projects() {
    return (
        <StyledProjectsContainer>
            <Container title="Projects">
                <Logo />
                <hr />

                <h1>Web Apps</h1>
                <div className="projects">
                    <ProjectUI project={review_chinese} />
                    <hr />
                    <ProjectUI project={vbd} />
                    <hr />
                    <ProjectUI project={stay_in_shape} />
                </div>

                <h1>Websites</h1>
                <div className="projects">
                    <ProjectUI project={dis} />
                    <hr />
                    <ProjectUI project={antoineweber} />
                    <hr />
                    <ProjectUI project={lexco} />
                    <hr />
                </div>

                <h1>Games</h1>
                <div className="projects">
                    <ProjectUI project={fezeta} />

                    <hr />

                    <ProjectUI project={agame} />
                    <hr />
                </div>

                {/*   <h2></h2>
                <p>Category: </p>
                <p>Challenge: </p>

 */}

                <Footer />
            </Container>
        </StyledProjectsContainer>
    )
}

function ProjectUI({ project }) {
    return (
        <div className="project">
            <div className="project_grid">
                <div className="left">
                    <div>
                        <img src={`/images/${project.imageurl}.png`} alt="review_chinese" />
                    </div>
                </div>
                <div className="right">
                    <div>
                        <h2>{project.challenge}</h2>
                        <div>
                            {project.tags.map(tag => (
                                <span key={tag} className="tag">
                                    {tag}
                                </span>
                            ))}
                        </div>
                    </div>
                    <p>
                        <i>Pros:</i> {project.notable}
                    </p>
                    {
                        <div className="stack">
                            {project.stack.map(tech => (
                                <span
                                    key={tech}
                                    className="tag"
                                    style={{ backgroundColor: "#333" }}
                                >
                                    {tech}
                                </span>
                            ))}
                        </div>
                    }
                    <a className="fancy_link relative" href={project.url}>
                        {project.name}
                    </a>
                </div>
            </div>
        </div>
    )
}
